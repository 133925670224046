// Navbar
.drop-arrow{
  &::after{
    display: none;
  }
}

.header{
  .nav-link{
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    &:hover, &.active{
      font-weight: 600;
    }
    @media screen and (max-width: 991px) {
      padding: 0.5rem 0;
    }
  }
}

//Pagination
.report-pagination{
  .page-item{
    .page-link{
      border-radius: 0.5rem;
      font-family: 'Exo', sans-serif;
      font-weight: 500;
    }
    &:first-child, &:last-child{
      .page-link{
        border: 0px;
        background: transparent;
        padding-left: 0px;
        padding-right: 0px;
        font-family: 'Exo', sans-serif;
      }
    }
  }
}

.input-select{
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 263px;
  overflow-y: auto;
  .input-option{
    color: #152536;
    padding: 12px 16px;
    &:hover, &:active{
      background-color: #152536;
      color: #FFF;
    }
  }
}

.canvasjs-chart-credit{
  display: none;
}