//Body Color
.bg-gray-gradient{
  background: linear-gradient(to bottom, #fff 0px, #fff 329px, #f3f3f3 330px, #f3f3f3 100%);
}

.login{
  background-image:linear-gradient(to bottom, transparent 0px, transparent 582px, #f3f3f3 583px, #f3f3f3 100%), url('../images/loginbg.png');
}

//Text Color
.text-salmon{
  color: $salmon;
}
.text-silver{
  color: $silver;
}

//Font-Family
.font-exo{
  font-family: 'Exo', sans-serif;
}

//Object
.object{
  object-fit: cover;
  object-position: center;
}

//Form-control
.input-control{
  border-bottom: 1px solid #7a7a7a;
  text-align: center;
}

//Font Size
.text-3xl{
  @media (min-width: 1400px) {
    font-size: 3.125rem;
  }
}