/////  Font Family //////
$font-family-base: 'Oxygen', sans-serif;
$font-sans-serif-bundle: 'Ubuntu', sans-serif;

//Color
$primary: #ffd83d;
$white-smoke: #f1f1f1;
$gray-50: #949494;
$semi-black: #222222;
$salmon: #EF8A6C;
$silver: #C6C6C6;
$dark-gray: #AFAFAF;

///// Text //////
$headings-color: $semi-black;
$gray-900: $semi-black;

///// Font Size //////
// $h1-font-size: 3.125rem;
$h2-font-size: 2.5rem;

///// Form Control //////
$input-border-width: 0px;
$input-border-color: #7a7a7a;
$input-box-shadow: none;
$input-border-radius: 0rem;
$input-placeholder-color: #7a7a7a;

///// Card //////
$card-color: $gray-50;
$card-inner-border-radius: 0.5rem;
$card-border-radius: 0.5rem;
$card-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.06);
$card-cap-padding-y: 2.5rem;

///// Container //////
$container-padding-x: 3.75rem;

/////  Border //////
$border-color: #EFEFEF;

///// Button //////
$btn-font-family: 'Exo', sans-serif;
$btn-font-weight: 600;

///// Dropdown /////
$dropdown-link-hover-color: $semi-black;
$dropdown-link-hover-bg: $primary;
$dropdown-link-active-color: $semi-black;
$dropdown-link-active-bg: $primary;

///// Navbar //////
$navbar-padding-x: 0rem;
$navbar-padding-y: 0rem;
$navbar-nav-link-padding-x: 0rem;
$navbar-nav-link-padding-y: 2.5rem;
$nav-tabs-border-width: 0px;
$navbar-nav-link-color: $semi-black; 
$navbar-nav-link-hover-bg: transparent;
$navbar-nav-link-hover-color: $semi-black;
$navbar-nav-link-active-bg: transparent;
$navbar-nav-link-active-color: $semi-black;
$nav-tabs-link-hover-border-color: $primary;
$nav-tabs-link-active-color: $semi-black;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: $primary;

///// Pagination //////
$pagination-bg: #F5F5F5;
$pagination-padding-y: 0.688rem;
$pagination-border-color: #C6C6C6;
$pagination-hover-bg: $primary;
$pagination-hover-color: $semi-black;
$pagination-hover-border-color: $primary;
$pagination-active-color: $semi-black;

///// Table //////
$table-color: $semi-black;
$table-border-color: #e1e1e1;
$table-th-font-size: 0.875rem;
$table-cell-font-size: 0.75rem;
$table-th-font-weight: 700;
$table-th-text-transform: capitalize;

$custom-colors: (
  "dark-gray": $dark-gray,
);

@import "@webpixels/css/core/functions";
@import "@webpixels/css/core/variables";
@import "@webpixels/css/core/maps";
@import "@webpixels/css/core/mixins";

$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-border-color-values: map-loop($utilities-colors, rgba-css-var, "$key", "border");

$theme-colors: map-remove($theme-colors, "primary");

// Import Bootstrap / Webpixel
@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components/avatars";
@import "@webpixels/css/components/buttons";
@import "@webpixels/css/components/cards";
@import "@webpixels/css/components/dropdowns";
@import "@webpixels/css/components/navbars";
@import "@webpixels/css/components/navs";
@import "@webpixels/css/components/paginations";
@import "@webpixels/css/components/tables";
@import "@webpixels/css/components/spinners";

.btn-outline-semi-black{
  @include button-outline-variant($semi-black);
}
.btn-primary{
  @include button-variant($primary, $primary, $semi-black);
}
.btn-white-smoke{
  @include button-variant($white-smoke, $white-smoke, $black);
}

@import "@webpixels/css/utilities";
@import "bootstrap/scss/utilities/api";